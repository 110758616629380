









































































































































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { CMS } from "../models";
import { $config, $artist } from '../services';
import { mixins } from 'vue-class-component';
import { Translatable } from '@/mixins';


import CMSIcons from '../components/CMSIcons.vue';
import Toolbar from '../components/Toolbar.vue';
import PrimaryMenu from '../components/PrimaryMenu.vue';
import ArtistCard from '../components/ArtistCard.vue';
import VueCable from '../components/VueCable.vue';
import HeaderInfo from '../components/HeaderInfo.vue';
import DynamicSpacer from '../components/DynamicSpacer.vue';

@Component({
  components: {
    CMSIcons, Toolbar, PrimaryMenu, ArtistCard, VueCable, HeaderInfo, DynamicSpacer,
  }
})
export default class ArtistList extends mixins(Translatable)  {
  private lastScrollTop = 0;
  scrollDirection = 0;

  screenWidth = 0;
  get config(){
    return $config.store.config;
  }

  get sortedArtists(): CMS.Artist[] {
    return $artist.sorted;
  }

  get groupedArtists(): CMS.ArtistSetByLetter[] {
    return $artist.setsByLetter;
  }

  get isMobileView(){
    return this.screenWidth < 500;
  }

  themeTertiary(theme) {
    return this.config.themes[theme].tertiary;
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    next()
  }

  mounted(){
    console.log("artist list mounted")
    document.body.classList.add('body-scroll');
    addEventListener('resize', this.onResize);
    this.computeScreenWidth();

    window.addEventListener("scroll", () => { 
      const st = window.pageYOffset || document.documentElement.scrollTop;
      //
      // downscroll code
      if (st > this.lastScrollTop){
        this.scrollDirection = 1;
      } 
      //
      // upscroll code
      else {          
        this.scrollDirection = -1;
      }

      //
      // For Mobile or negative scrolling
      this.lastScrollTop = st <= 0 ? 0 : st; 
    }, false);

  }

  beforeDestroy() {
    console.log("artist list removed")
    document.body.classList.remove('body-scroll');
    removeEventListener('resize', this.onResize);
  }

  computeScreenWidth(){
    this.screenWidth = window.innerWidth;
  }

  onResize(){
    this.computeScreenWidth();
  }

  async onBack() {
    this.$router.go(-1);
  }

  async onLoad(slug: string) {
    //
  }

  async onTop($event) {
    const element = document.getElementById('artists-top');
    if(!element) {
      return;
    }
    element.scrollIntoView({ behavior: 'smooth' });
  }



  async onSave() {
    //
  }
}
