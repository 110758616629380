






































import { Component, Vue, Prop } from 'vue-property-decorator';

import { $config } from '../services';


@Component({
  components: {
  }
})
export default class HeaderInfo extends Vue {


  screenWidth = 0;

  get isMobileView(){
    return this.screenWidth <= 476;
  }

  mounted(){
    window.addEventListener('resize', this.onResize);
    this.computeScreenWidth();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
  
  computeScreenWidth(){
    this.screenWidth = window.innerWidth;
  }

  onResize(){
    this.computeScreenWidth();
  }

  get config(){
    return $config.store.config;
  }


}
