import { render, staticRenderFns } from "./Program.vue?vue&type=template&id=c0cd6c20&scoped=true&"
import script from "./Program.vue?vue&type=script&lang=ts&"
export * from "./Program.vue?vue&type=script&lang=ts&"
import style1 from "./Program.vue?vue&type=style&index=1&id=c0cd6c20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0cd6c20",
  null
  
)

export default component.exports