


















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Route } from 'vue-router';
import { CMS } from "../models";
import { $config, $cms, $i18n } from '../services';
import { Translatable } from '../mixins';

import CMSIcons from '../components/CMSIcons.vue';
import Toolbar from '../components/Toolbar.vue';
import Calendar from '../components/Calendar.vue';
import HeaderInfo from '../components/HeaderInfo.vue';


@Component({
  components: {
    CMSIcons, Toolbar,Calendar, HeaderInfo,
  }
})
export default class EventList extends mixins(Translatable) {
  
  today = new Date();
  toolbarExit = true;
  defaultTitle = {
    name:{fr:"Live",en:"Live"}
  }
  currentTitle: any = {}


  get config(){
    return $config.store.config;
  }

  get title() {
    const menu = this.getMenu('primary').find(item => item.selected) || this.defaultTitle ;
    return menu.name;
  }

  getMenu(layout) {
    const menu = [... $config.getMenu(layout)];
    const path = (this.$route.query.selected as string ||'').toLowerCase();

    const itemIdx = menu.findIndex(item => {
      const name = item.name['fr'].toLowerCase();
      return name.indexOf(path)>-1;
    });
    menu.forEach(item => item.selected = false);
    menu[(itemIdx == -1)? 0:itemIdx].selected = true;
    return menu;
  }

  themeTertiary(theme) {
    return this.config.themes[theme].tertiary;
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    next();
  }

  beforeDestroy() {
    document.body.classList.remove('body-scroll');
  }

  async mounted(){
    document.body.classList.add('body-scroll');
    this.currentTitle = this.title;
  }

  async onBack() {
    // this.$router.go(-1);
    this.$router.push({ path: '/' });
  }

  async onEventCategory(name) {
    const label = (name||'all').toLowerCase();
    this.$router.replace({ query: { selected: label }}).catch(()=>{});    
  }

  async onEvent(event: CMS.Event) {
    this.$router.push({ path: `/events/${event.slug}` });
  }

  async onLoad(slug: string) {
    //
  }

  async onToolbarExit($exited) {
    this.toolbarExit = $exited;
  }

  async onSave() {
    //
  }

  async onCalendarUpdate() {
    this.currentTitle = this.title;
  }


}
