












































































































































































































import { Translatable } from '@/mixins';
import { mixins } from 'vue-class-component';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CMS } from "../models";
import CMSIcons from '../components/CMSIcons.vue';
import { $config, $page } from '../services';

import Toolbar from './Toolbar.vue';
import PrimaryMenu from './PrimaryMenu.vue';
import LanguageSelector from './LanguageSelector.vue';

@Component({
  components: { 
    PrimaryMenu, LanguageSelector, Toolbar, CMSIcons 
  }
})
export default class NavigationDesktop extends mixins(Translatable) {

  private lastScrollTop = 85;
  stickyNav = 0;
  displayMenu = false;

  screenWidth = 0;

  get isMobileView(){
    return this.screenWidth <= 476;
  }

  get config(){
    return $config.store.config;
  }

  get primaryMenu(){
    const layout = "primary";
    let menu = [... $config.getMenu(layout)];
    const path = this.$router.currentRoute.fullPath;
    console.log("full path", path);
    menu.forEach(item => item.selected = false);

    return menu;
  }

  get secondaryMenu(){
      return $page.pageLinks;
  }

  get footerMenu() {
    const layout = "footer";
    let menu = [... $config.getMenu(layout)];
    const path = this.$router.currentRoute.fullPath;
    console.log("nav full path", path);
    menu.forEach(item => item.selected = false);

    return menu;

  }
  externalLink(link){
    return link.indexOf('http') === 0 || link.indexOf('mailto') === 0 ;
  }

  mounted(){
    window.addEventListener("scroll", this.onScroll, false);


    window.addEventListener('resize', this.onResize);
    this.computeScreenWidth();

  }

  onScroll(){
    const isMobile = $config.isMobile();
    const st = window.pageYOffset || document.documentElement.scrollTop;
    //
    // downscroll code
    if (st > this.lastScrollTop && !isMobile){
      this.stickyNav = 1;
    } 
    //
    // upscroll code
    else {          
      this.stickyNav = -1;
    }

    //
    // For Mobile or negative scrolling
    // this.lastScrollTop = st <= 0 ? 0 : st; 
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, false);
    window.removeEventListener('resize', this.onResize);
  }
  
  computeScreenWidth(){
    this.screenWidth = window.innerWidth;
  }

  onResize(){
    this.computeScreenWidth();
  }

  onDark(){
    $config.toggleDarkMode();
  }

  //
  // toggle menu when:
  // - click on burger
  // - click on close
  // - click on action
  onMenu() {
    document.body.classList.toggle('menu-open');
  }
  onRoute($event,link) {
    document.body.classList.remove('menu-open');
    if(this.externalLink(link)) {
      window.open(link,'_blank');
    }else {
      this.$router.push(link).catch(() => {});
    }
  }
  onClose() {
    document.body.classList.remove('menu-open');
  }

  onHome(){
    this.$router.push('/').catch(() => {});
  }
}
