















































































































































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { CMS } from "../models";
import { $config, $artist } from '../services';

import CMSIcons from '../components/CMSIcons.vue';
import Toolbar from '../components/Toolbar.vue';
import PrimaryMenu from '../components/PrimaryMenu.vue';
import VideoPlayer from '../components/VideoPlayer.vue';
import SoundCloud from 'vue-soundcloud-player';
import SocialIcons from '../components/SocialIcons.vue';
import EventCard from '../components/EventCard.vue';
import HeaderInfo from '../components/HeaderInfo.vue';

import { mixins } from 'vue-class-component';
import { Translatable } from '@/mixins';


@Component({
  components: {
    CMSIcons, Toolbar, PrimaryMenu, VideoPlayer, SoundCloud, SocialIcons, EventCard, HeaderInfo,
  }
})
export default class Artist extends mixins(Translatable) {

  get config(){
    return $config.store.config;
  }

  themeTertiary(theme) {
    return this.config.themes[theme].tertiary;
  }

  // *this* does not exist at this point
  beforeRouteEnter(to: Route, from: Route, next: any) {
    const slug = to.params.artist;
    const artistExists = !!$artist.artistWithSlug(slug);
    if(!artistExists){
      next({name:'NotFound'});
    }else{
      next();
    }
  }
  
  // *this* does not exist at this point
  beforeRouteUpdate(to: Route, from: Route, next: any) {
    const slug = to.params.artist;
    const artistExists = !!$artist.artistWithSlug(slug);
    if(!artistExists){
      next({name:'NotFound'});
    }else{
      next();
    }
  }

  mounted(){
    document.body.classList.add('body-scroll');
    try{window.scrollTo(0,0);}catch(e){
      //
    }
  }

  beforeDestroy() {
    document.body.classList.remove('body-scroll');
  }

  get artist(): CMS.ArtistWrap {
    const artist = $artist.artistWithSlug(this.$route.params.artist) as CMS.Artist;
    return new CMS.ArtistWrap(artist);
  }

  get mediaCount(): number {
    return this.externalVideos.length + this.externalSoundCloud.length + this.localImages.length + this.externalImages.length;
  }
  
  get externalVideos(): CMS.ExternalMedia[] {
    return this.artist.externalMedias.filter(m => m.platform == "youtube" || m.platform == "vimeo");
  }

  get externalSoundCloud(): CMS.ExternalMedia[]{
    return this.artist.externalMedias.filter(m => m.platform == "soundCloud");
  }

  get localImages(): CMS.LocalMedia[]{
    const result = this.artist.localMedias.filter(m => m.image);
    return result;
  }

  get externalImages(): CMS.ExternalMedia[]{
    const result = this.artist.externalMedias.filter(m => m.platform === "img");
    return result;
  }

  get socialMedia(): CMS.SocialMedia[]{
    const result = this.artist.socialMedias;
    return result;
  }

  get events(): CMS.Event[]{
    const result = $artist.eventsForArtist(this.artist);
    console.log("events for artist", result);
    return result;
  }

  async onBack() {
    this.$router.go(-1);
  }

  async onLoad(slug: string) {
    //
  }

  async onSave() {
    //
  }
}
