
































































































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { CMS } from "../models";
import { $config, $news, $i18n } from '../services';

import CMSIcons from '../components/CMSIcons.vue';
import Toolbar from '../components/Toolbar.vue';
import HeaderInfo from '../components/HeaderInfo.vue';

import { mixins } from 'vue-class-component';
import { Translatable } from '@/mixins';


@Component({
  components: {
    CMSIcons, Toolbar, HeaderInfo, 
  }
})
export default class NewsItem extends mixins(Translatable) {

  get config(){
    return $config.store.config;
  }

  mounted(){
    document.body.classList.add('body-scroll');
    document.body.scrollTop = 0;
    try{window.scrollTo(0,0);}catch(e){
      //
    }
  }

  beforeDestroy() {
    document.body.classList.remove('body-scroll');
  }

  themeTertiary(theme) {
    return this.config.themes[theme].tertiary;
  }

  // *this* does not exist at this point
  beforeRouteEnter(to: Route, from: Route, next: any) {
    const slug = to.params.news;
    const itemExists = !!$news.newsWithSlug(slug);
    if(!itemExists){
      next({name:'NotFound'});
    }else{
      next();
    }
  }
  
  // *this* does not exist at this point
  beforeRouteUpdate(to: Route, from: Route, next: any) {
    const slug = to.params.news;
    const itemExists = !!$news.newsWithSlug(slug);
    if(!itemExists){
      next({name:'NotFound'});
    }else{
      next();
    }
  }
  
  get newsItem(): CMS.News {
    return $news.newsWithSlug(this.$route.params.news) as CMS.News;
  }

  async onLoad(slug: string) {
    //
  }

  async onSave() {
    //
  }
}
