













































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { CMS } from "../models";
import { $config, $event, $i18n } from '../services';
import { mixins } from 'vue-class-component';
import { Translatable } from '@/mixins';


import CMSIcons from '../components/CMSIcons.vue';
import Toolbar from '../components/Toolbar.vue';
import EventCard from '../components/EventCard.vue';
import HeaderInfo from '../components/HeaderInfo.vue';
import DynamicSpacer from '../components/DynamicSpacer.vue';

type FilterType = "all" | "Collection virtuelle";

@Component({
  components: {
    CMSIcons, Toolbar, EventCard, HeaderInfo, DynamicSpacer,
  }
})
export default class Program extends mixins(Translatable)  {
  private lastScrollTop = 0;
  scrollDirection = 0;

  filter: FilterType = "all";

  screenWidth = 0;
  get config(){
    return $config.store.config;
  }

  get title() : { fr: string, en: string } {

    if(this.filter == 'Collection virtuelle'){
      return {
        fr: "Collection virtuelle",
        en: "Virtual collection",
      };
    } else{
      return {
        fr: 'Événements',
        en: "Events",
      };
    }
  }

  get currentLang(): string{
    const result = $i18n.lang;
    return result;
  }

  get sortedEventSets(): CMS.EventSetByType[] {
    return $event.getSetsByLetter(this.currentLang);
  }

  get events(): CMS.Event[]{
    let result = $event.all;

    console.log("route", this.$router.currentRoute.params);
    if(this.filter == 'Collection virtuelle'){
      result = result.filter(e => e.typology as string == 'Collection virtuelle')
      .sort((a, b) => a.title[this.currentLang].localeCompare(b.title[this.currentLang]));
    }

    return result;
  }

  themeTertiary(theme) {
    return this.config.themes[theme].tertiary;
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    next()
  }

  mounted(){
    try{window.scrollTo(0,0);}catch(e){
      //
    }

    window.addEventListener("scroll", () => { 
      const st = window.pageYOffset || document.documentElement.scrollTop;
      //
      // downscroll code
      if (st > this.lastScrollTop){
        this.scrollDirection = 1;
      } 
      //
      // upscroll code
      else {          
        this.scrollDirection = -1;
      }

      //
      // For Mobile or negative scrolling
      this.lastScrollTop = st <= 0 ? 0 : st; 
    }, false);

  }

  beforeDestroy() {
    console.log("artist list removed")
    document.body.classList.remove('body-scroll');
  }

  computeEventTypeTranslation(eventType: string){
    if(eventType == 'Collection virtuelle'){
      return{
        fr: 'Collection virtuelle',
        en: 'Virtual Collection',
      };
    }else{
      return {
        fr: eventType,
        en: eventType,
      }
    }
  }

  async onBack() {
    this.$router.go(-1);
  }

  async onLoad(slug: string) {
    //
  }

  async onTop($event) {
    const element = document.getElementById('artists-top');
    if(!element) {
      return;
    }
    element.scrollIntoView({ behavior: 'smooth' });
  }

  @Watch('$route', { immediate: true, deep: true })
  async onRouteUpdate(to) {

    if(this.$router.currentRoute.params.selected == 'Collection virtuelle'){
      this.filter = 'Collection virtuelle';
    }else{
      this.filter = 'all';
    }
  }
}
