















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import LanguageSelector from '../components/LanguageSelector.vue';
import PrimaryMenu from '../components/PrimaryMenu.vue';
import { $config } from '../services';

@Component({
  components: {
    LanguageSelector, PrimaryMenu
  }
})
export default class Toolbar extends Vue {
  private lastScrollTop = 0;
  private exited = true;
  scrollDirection = 0;
  darkMode = false;
  @Prop() tiny!:boolean;
  main = true;

  async mounted(){
    //
    // switch mode
    this.main = ! this.tiny;

    window.addEventListener("scroll", () => { 
      const st = window.pageYOffset || document.documentElement.scrollTop;
      //
      // downscroll code
      if (st > this.lastScrollTop){
        this.scrollDirection = 1;
      } 
      //
      // upscroll code
      else {          
        this.scrollDirection = -1;
      }

      //
      // For Mobile or negative scrolling
      this.lastScrollTop = st <= 0 ? 0 : st; 

      //
      // fire event
      if(!this.exited && (this.scrollDirection <= 0)) {
        this.exited = true;
        this.$emit('exited', true);
      }
      if(this.exited && (this.scrollDirection > 0)) {
        this.exited = false;
        this.$emit('exited',false);
      }


    }, false);
  }

  get config(){
    return $config.store.config;
  }

  async onBack() {
    this.$router.go(-1);
  }  

  async onDark() {
    this.darkMode = ! this.darkMode;
    const root = document.documentElement;
    const fcolor = this.darkMode ? 'white':'black';
    const bcolor = this.darkMode ? 'black':'white';
    root.style.setProperty('--font-color', fcolor);
    root.style.setProperty('--main-font-color', fcolor);
    root.style.setProperty('--body-color', bcolor);
  }

  //
  // decorate body to inform sidenav in others components
  async onMenu() {
    //this.$emit('click');
    document.body.classList.toggle('menu-open');
  }
}
