

















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CMS } from "../models";
import { $config } from '../services';
import LazyImg from './LazyImg.vue';

@Component({
  components: { LazyImg }
})
export default class ArtistCard extends Vue {
  @Prop() artist!: CMS.Artist;
  @Prop() mobileView!: boolean;

  get config(){
    return $config.store.config;
  }

  get coverMobile(): string {
    if(this.artist.cover){
      return this.artist.cover.sizes.thumbs.path;
    }else{
      return "https://via.placeholder.com/80/000000/000000";
    }
  }
  
  get coverDesktop(): string {
    if(this.artist.cover){
      return this.artist.cover.sizes.headerimage.path;
    }else{
      return "https://via.placeholder.com/150/000000/000000";
    }
  }

  get artistNameDesktop(): string{
    let firstLine = ""
    if(this.artist.artistName || this.artist.lastname){
      // we are on two lines
      firstLine = `<span>${this.artist.artistName || this.artist.lastname}</span><br>`;
    }

    let secondLine = "";
    const supStyle = 'style="font-size:xx-small;vertical-align:super"';
    if(this.artist.artistName){
      secondLine = `${this.artist.lastname} ${this.artist.firstname} <sup ${supStyle}>${this.artist.country}</sup>`;
    }else{
      const lastName = (this.artist.lastname && this.artist.artistName) ? `${this.artist.lastname} ` : "";
      secondLine = `${lastName}${this.artist.firstname} <sup ${supStyle}>${this.artist.country}</sup>`;
    }

    return `${firstLine} ${secondLine}`;

  }

  navigateToArtist(){
    console.log("on click----------------------------");
    this.$router.push({path: `/artists/${this.artist.slug}`});
  }
}
