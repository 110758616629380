


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { $config } from '../services';

@Component
export default class SocialIcons extends Vue {
  @Prop() private name!: string;
  @Prop({ default : '32'}) private width!: string;
  @Prop({ default : '32'}) private height!: string;
  @Prop({ default : 'white' }) private color!: string;
  @Prop() private url!: string

  async created() {
  }

  get config() {
    return $config.store.config;
  }

  onClick(){

  }

  get viewBox() {
    return "0 0 100 100";
  }
}
