















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { CMS } from "../models";
import { $config, $event, $i18n } from '../services';

import CMSIcons from '../components/CMSIcons.vue';
import Toolbar from '../components/Toolbar.vue';
import VideoPlayer from '../components/VideoPlayer.vue';
import PrimaryMenu from '../components/PrimaryMenu.vue';
import SoundCloud from 'vue-soundcloud-player';
import SpotCard from '../components/SpotCard.vue';
import HeaderInfo from '../components/HeaderInfo.vue';
import DynamicSpacer from '../components/DynamicSpacer.vue';

import { mixins } from 'vue-class-component';
import { Translatable } from '@/mixins';


@Component({
  components: {
    CMSIcons, Toolbar, VideoPlayer, SoundCloud, SpotCard, HeaderInfo, DynamicSpacer,
  }
})
export default class Event extends mixins(Translatable) {

  date: Date | null = null;

  get config(){
    return $config.store.config;
  }

  get backgroundImage(){
    const defaultImg = 'https://via.placeholder.com/450/000000/000000';
    const cover = this.event.cover as any;
    const image = (cover &&  cover.sizes) ? cover.sizes.headerimage.path:defaultImg;
    return {
      backgroundImage:  'url(' + image + ') ',
      backgroundRepeat: 'no-repeat',
      backgroundSize :'cover',
      backgroundPosition: '50%'
    };
  }

  get intervals(): CMS.Interval[]{
    const intervals = new CMS.EventWrap(this.event).intervals;
    // console.log("intevals", intervals);
    return intervals;
  }

  get price(): string{
    if(!this.event.price || this.event.price == 0){
      return this.t({fr: "Entrée libre", en:"Free"}) as string;
    }
    return `CHF ${this.event.price}.-`;
  }


  getTypeLabel(type) {
    return $i18n.t(type);
  }
  
  // //
  // // get first available date after now
  // get when(){
  //   const now = Date.now();
  //   const find = (this.event.when||[]).find(when => when._id > now );
  //   return find ? find.start:null;
  // }

  mounted(){
    document.body.classList.add('body-scroll');
    document.body.scrollTop = 0;
    try{window.scrollTo(0,0);}catch(e){
      //
    }
    
    if(this.$router.currentRoute.query.when){
      const split = (this.$router.currentRoute.query.when as string).split("-");
      if(split.length == 3){
        const y = parseInt(split[0]);
        const m = parseInt(split[1]);
        const d = parseInt(split[2]);

        if(m >0 && m <= 12){
          const aDate = new Date(y, m - 1, d);
          if(!isNaN(aDate.getTime())){
            this.date = aDate;
          }
        }
      }
    }


  }

  beforeDestroy() {
    document.body.classList.remove('body-scroll');
  }

  themeTertiary(theme) {
    return this.config.themes[theme].tertiary;
  }

  // *this* does not exist at this point
  beforeRouteEnter(to: Route, from: Route, next: any) {
    const slug = to.params.event;
    const eventExists = !!$event.eventWithSlug(slug);
    if(!eventExists){
      next({name:'NotFound'});
    }else{
      next();
    }
  }
  
  // *this* does not exist at this point
  beforeRouteUpdate(to: Route, from: Route, next: any) {
    const slug = to.params.event;
    const eventExists = !!$event.eventWithSlug(slug);
    if(!eventExists){
      next({name:'NotFound'});
    }else{
      next();
    }
  }
  
  get event(): CMS.Event {
    return $event.eventWithSlug(this.$route.params.event) as CMS.Event;
  }

  get eventLocation(): CMS.EventLocation | null{
    return new CMS.EventWrap(this.event).eventLocation;
  }

  get artists(): CMS.ArtistWrap[]{
    return $event.artistsForEvent(this.event).map(a => new CMS.ArtistWrap(a))
  }

  get whens(): CMS.When[]{
    const result = this.event.when;
    console.log("whens", result);
    return result;
  }

  get mediaCount(): number {
    return this.externalVideos.length + this.externalSoundCloud.length + this.localImages.length + this.externalImages.length;
  }
  
  get externalVideos(): CMS.ExternalMedia[] {
    return this.event.externalMedias.filter(m => m.platform == "youtube" || m.platform == "vimeo");
  }

  get externalSoundCloud(): CMS.ExternalMedia[]{
    return this.event.externalMedias.filter(m => m.platform == "soundCloud");
  }

  get localImages(): CMS.LocalMedia[]{
    const result = this.event.localMedias.filter(m => m.image);
    return result;
  }

  get externalImages(): CMS.ExternalMedia[]{
    const result = this.event.externalMedias.filter(m => m.platform === "img");
    return result;
  }

  async onLoad(slug: string) {
    //
  }

  async onSave() {
    //
  }
}
